html, body {
    overflow-x:hidden;
    width:100%;
   /* You may also want to try adding:*/
    margin: 0;
    padding: 0;
}

// this is the place for your components / CSS
.time-select {
    width: auto;
    height: auto;
}

.calendar-available {
    background-color: #f0f0ff;
    // border-left: 8px solid #636aee;
  }

.is-disabled {
    opacity: 0.7;
}

.MuiSlider-root {
    color: $primary;
    height: 8px;
}

.MuiSlider-mark {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin-top: -2px;
    margin-left: -2px;
    background-color: #fff;
    border: 2px solid currentColor;
}

.MuiSlider-thumb {
    height: 24px;
    width: 24px;
    margin-top: -8px;
    margin-left: -12px;
    &:focus, &:hover, &:active {
        box-shadow: inherit;
    };
}


.MuiSlider-valueLabel {
    left: calc(-50% + 8px);
}

.MuiSlider-track {
    height: 8px;
    border-radius: 4px;
}
.MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
}

.MuiSlider-marks {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin-top: -2px;
    margin-left: -2px;
}

.CalendarDay__today {
    /*Your styles here*/
    font-weight: bold;
    background-color: lighten($primary, 30%);
  }
.CalendarDay__selected {
    /*Your styles here*/
    background-color: $primary;
}

.place-suggestion-li {

    &:hover {
        background-color: lighten($primary, 30%);
    }
}
.signup-toast {
    position: fixed;
    left: 50%;
    top: calc(#{$navbarHeight} + 1rem);
    z-index: $zindex-tooltip;
    transform: translateX(-50%);
}
.info-toast {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: $zindex-tooltip;
}

.row-cta {
    white-space: nowrap;
    font-size: $font-size-sm;
    padding: 0;
}

.dashboard-title {
    font-size: 1.25rem;
    font-size: max(1.0rem, min(1.25rem, 3.5vw));
}

.color-legend {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    border-radius: 2px;
    border: 1px solid gray;
}

.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 0px;
  }

.youtube-container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

.fancy-underline-lg {
    text-decoration: underline;
    text-decoration-color: lighten($primary, 10%);
    text-decoration-thickness: 10px !important;
    text-decoration-skip-ink: none;
    text-underline-position: under;
}

.fancy-underline {
    text-decoration: underline;
    text-decoration-color: lighten($primary, 10%);
    text-decoration-thickness: 5px !important;
    text-decoration-skip-ink: none;
    text-underline-position: under;
}

.ul-primary {
    list-style: none;
} 
.ul-primary li::before {
    content: "\2022 ";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold;
    color: lighten($primary, 10%); /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
  }

  .pre-wrap {
      white-space: pre-wrap;
  }

  .instagram {
  border: 0;
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    }

.scroll-margin-top {
    scroll-margin-top: 10rem;
}

.class-cover {
    height: auto; 
    width: 100%;
    // max-width: 940px;
    max-height: 540px;
    object-fit: cover;
}

.pointer { 
  cursor: pointer;
}

.below-header {
    top: $headerHeight;
}

#class_search_form_date {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-weight: bold;
    font-size: 0.8rem;
}